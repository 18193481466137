import styled from 'styled-components';

const Button = styled.button`
  color: white;
  border: ${props => `2px solid ${props.theme.colors.orange}`};
  background-color: ${props => props.theme.colors.orange};
  border-radius: ${props => props.theme.borderRadius};
  letter-spacing: 2px;
  font-size: 18px;
  text-transform: uppercase;
  font-family: Gibson;
  font-weight: 600;
  padding: 1em 3em;
  margin: 0;
`;

export default Button;
