import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
    borderRadius: "10px",
    colors: {
        orange: "#EF6236",
        black: "#333",
        gray: "#5C5C5C",
        beige: "#F3ECCE",
        white: "#FEFEFE",
        yellow: "#F7BF4DDE"
    }
};

export default theme;
