import React, {FormEvent, useState} from 'react';
import styled from 'styled-components';
import Button from './Button';

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  :active {
    opacity: 0.5;
  }
`;

const StyledContainer = styled.div`
  background-color: #faf6e8;
  word-wrap: break-word;
  padding: 20px 10px 10px 10px;
  h2 {
    margin-top: 0;
    text-align: center;
  }
  p {
    margin-bottom: 0;
  }
  form, div {
    width: calc(100vw - 25px);
    height: calc(100vh - 50px);
  }
  div {
    text-align: center;
  }
  textarea {
    width: 100%;
    height: 375px;
    font-size: 16px;
  }
`;

const SupportForm: React.FC = () => {
  const [feedback, setFeedback] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    console.log('submitting feedback ', feedback);
    // send to endpoint
    setIsFormSubmitted(true);
  };

  let pageBody = null;
    if (isFormSubmitted) {
      pageBody = (
        <div>Thank you for your feedback!</div>
      );
    } else {
      pageBody = (
        <form onSubmit={handleSubmit}>
          <label>
            Your feedback is extremely valuable to us. Please share any information you would like in order to help
            us improve Focus Friend or to report any bugs that you may have found when using the product.
          </label>
          <textarea value={feedback} onChange={e => setFeedback(e.target.value)}/>
          <StyledButton>Submit</StyledButton>
        </form>
      );
    }

  return (
    <StyledContainer>
      {pageBody}
    </StyledContainer>
  );
}

export default SupportForm;