import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import Theme from './theme';
import './App.css';
import SupportForm from './SupportForm';
import Privacy from './Privacy';
import focusFriendLogoImg from './images/focus-friend-logo.png';
const AppHeader = styled.div`
  text-align: center;
  width: 100%;
  img {
    max-width: 50%;
    max-height: 100%;
    margin-top: 1em;
  }
  background-color: #faf6e8;

`;

const App: React.FC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle/>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <div className="App">
              <header className="App-header">
                <img src={focusFriendLogoImg} className="App-logo" alt="logo" />
                <p>
                  Guided relaxation through breathwork. <br/> Now on iOS.
                </p>
              </header>
            </div>
          </Route>
          <Route>
            <AppHeader>
              <img src={focusFriendLogoImg} alt="Focus Friend" />
            </AppHeader>
            <Switch>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/support">
                <SupportForm />
              </Route>
            </Switch>
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
